import React from 'react';
import { animated, useSpring } from "react-spring";

const CountComponent = ({ n }) => {
  const { number, styles } = useSpring({
    from: { number: 0 },
    number: n,
    styles: "",
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return (
    <animated.div style={styles}>{number.to((n) => n.toFixed(0))}</animated.div>
  );
};

export default CountComponent;
