import React from "react";
import Navbar from "../components/global/Navbar";
import Footer from "../components/global/Footer";
import ErrorComponent from "../components/global/ErrorComponent";

function ErrorPage() {
  return (
    <>
      <Navbar />
      <ErrorComponent />
      <Footer />
    </>
  )
}

export default ErrorPage;