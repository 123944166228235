import React from 'react';
import ProjectsComponent from "../components/ProjectsComponent";


function ProjectsPage() {
  return (
    <div>
      <ProjectsComponent />
    </div>
  );
}

export default ProjectsPage;