import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import { Button } from 'primereact/button';
import "../assets/styles/LoginSection.css";
import loginSectionBg from "../assets/images/main/login-section-bg.mp4";
const confirm = (event) => {
  confirmDialog({
      trigger: event.currentTarget,
      message: 'Dziękuję za pozostawienie wiadomości',
      header: 'Logowanie',
      acceptLabel: 'Tak',
      rejectLabel: 'Nie',
      ariaCloseIconLabel: 'Nie',
      accept: () => acceptFunc(),
      reject: () => rejectFunc()
  });
}
function LoginSection({className}) {
  return (
    <div className={`login-section ${className}`}>
      {/* <video src={loginSectionBg} autoPlay loop muted /> */}
      <div
        className="login-bg-video"
        dangerouslySetInnerHTML={{
          __html: `<video autoplay loop muted playsinline>
      <source src=${loginSectionBg} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
        }}
      />
      <div className="login-section-container">
        <div className="login-section-content flex flex-col center text-align-center">
          <h2 className="center">Spodobały Ci się projekty?</h2>
          <p className="center">W przypadku uwag lub pomysłów, proszę pozostaw notatkę z sugestami co mogę poprawić</p>
          <p className="center">Aplikacja w trakcie budowy</p>
          <div className="flex center">
              {/* <button className="login-section-btn">
                Zaloguj się
              </button> */}
              
<ConfirmDialog />
<Button onClick={confirm} label="Zaloguj się"></Button>

            </div>
        </div>
     
      </div>
    </div>
  );
}

export default LoginSection;
