import "./App.css";
import React from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
//theme
import "./assets/styles/ThemePrime.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import HomePage from "./pages/HomePage";
import ProjectsPage from "./pages/ProjectsPage";
import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";

const router = createBrowserRouter([
  {path: '/', element: <RootLayout />, errorElement: <ErrorPage />, children: [
    {path: "/", element: <HomePage />},
    {path: "/projects", element: <ProjectsPage />},
  ]}

])

function App() {
  return (
   <RouterProvider router={router} />
  );
}

export default App;
