import React from 'react';
import "../../assets/styles/Footer.css";
import linkedin from "../../assets/icons/linkedin.svg";
import github from "../../assets/icons/github.svg";
import handThumbsUp from "../../assets/icons/hand-thumbs-up.svg";
import phone from "../../assets/icons/phone.svg";
import envelope from "../../assets/icons/envelope.svg";

function Footer() {
  return (
      <footer className='footer'>
        <div className='flex center'><h2 className='section-title'>Kontakt</h2></div>
        <div className='footer-content-container flex p-10'>
        <div className='flex-half-containers mt-100'><h3 className='flex center section-subtitle '>Pozostańmy w kontakcie.</h3></div>
        <div className=' flex-half-containers mt-100'>
          <div className='flex footer-box-container'>
           
            <div className='first-box flex center'>  
              <img
                className="footer-svg-size"
                src={handThumbsUp}
                alt="handThumbsUp icon"
              />
            </div>
            <div className='second-box '>
            <div><h3>Social media</h3></div>
            <div>
              <a className='footer-img-link' href='https://www.linkedin.com/in/piotr-slawek-dev/' target='_blank' rel='noopener noreferrer'>     
              <img
                className="footer-svg-size footer-svg-size--linkedin"
                src={linkedin}
                alt="linkedin icon"
              /> 
              </a>   
              <a className='footer-img-link' href='https://github.com/Pittt1' target='_blank' rel='noopener noreferrer'> 
              <img
                className="footer-svg-size footer-svg-size--github"
                src={github}
                alt="github icon"
              /></a></div>
            </div>
          </div>
          <div className='flex footer-box-container'>
           
            <div className='first-box flex center'>  
              <img
                className="footer-svg-size"
                src={phone}
                alt="phone icon"
              />
            </div>
            <div className='second-box '>
            <div><h3>Telefon</h3></div>
                <p>+48 699 980 572</p>
            </div>
            
          </div>
          <div className='flex footer-box-container'>
           
           <div className='first-box flex center'>  
             <img
               className="footer-svg-size"
               src={envelope}
               alt="envelope icon"
             />
           </div>
           <div className='second-box '>
           <div><h3>Email</h3></div>
               <p>piotr.slawek@o2.pl</p>
           </div>
           
         </div>
        </div>
        </div>
      </footer>
  );
}

export default Footer;