import React from 'react';
import "../assets/styles/AboutMe.css";
import myselfPhoto from "../assets/images/main/myselfPhoto.jpg";

import { TabView, TabPanel } from "primereact/tabview";
const AboutMe = () => {
  return (
    <div className="about-me">
      <div className="top-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120">
          <path
            fill="#F9FAFB"
            fill-opacity="1"
            d="M0,96L1440,32L1440,0L0,0Z"
          ></path>
        </svg>
      </div>
      <div className="text-about-me flex">
        <div className="flex-half-containers flex center">
          <img src={myselfPhoto} alt="myselft photography in portfolio" />
        </div>
        <div className="flex-half-containers">
          <h2>Czym się zajmuje?</h2>
          <div className="content-container">
            <div className="text-what-doing">
              <p>
                Jestem Front-end Developerem z kilkuletnim doświadczeniem pracy
                w IT. Pasjonuje mnie sprzęt komputerowy oraz technologie
                informatyczne, dlatego rozwijam bloga, w którym opisuje tworzone
                projekty i testowane technologie oraz sprzęt. Lubię aktywnie
                spędzać czas na świeżym powietrzu, dzięki czemu do głowy
                przychodzą nowe pomysły.
              </p>
            </div>
            <TabView className="tab-container">
              <TabPanel header="Wykształcenie.">
                <p className="m-0">
                  <p>
                    Cenię sobie ciągły rozwój, co sprawia, że lubię uczyć się od
                    najlepszych zarówno w pracy jak i w życiu prywatnym.
                  </p>
                  <ul>
                    <li>Politechnika Warszawska</li>
                    <li>Akademia Górniczo-Hutnicza</li>

                    <li>
                      liczne kursy, wiedza z książek, artykułów - Coursera,
                      Udemy, książki, wyselekcjonowane blogi innych Developerów
                    </li>
                  </ul>
                </p>
              </TabPanel>
              <TabPanel header="Praca zawodowa.">
                <TabView className="tab-container">
                  <TabPanel header="ECTOSI Technology">
                    <h3>Owner / Front-end Developer </h3>
                    <p className="m-0">
                      06-2022 - obecnie <br />
                      <span className="skillset">
                        Stack: React · Next · PrimeReact · Vue 3 · Nuxt3 ·
                        Tailwind · SCSS · Bootstrap · PrimeVue · PrimeFlex · BEM
                        · Vuetify{" "}
                      </span>
                      <br />
                      Zakres obowiązków - Tworzenie oraz utrzymywanie
                      następujących aplikacji:
                      <ul>
                        <li>ectosi.com - strona firmowa (w trakcie)</li>
                        <li>devoiam.com - portfolio (w trakcie)</li>
                        <li>
                          smaczkuj.pl - blog na temat odżywiania (w trakcie)
                        </li>
                      </ul>
                    </p>
                  </TabPanel>
                  <TabPanel header="Refericon">
                    <h3>Front-end Developer</h3>
                    <p className="m-0">
                      09-2022 - obecnie <br />
                      <span className="skillset">
                        Stack: Vue 2/3 · Nuxt3 · Tailwind · SCSS · PrimeVue ·
                        PrimeFlex · BEM · Vuetify{" "}
                      </span>
                      <br />
                      Zakres obowiązków - Tworzenie oraz utrzymywanie
                      następujących aplikacji:
                      <ul>
                        <li>strategialean.pl - blog</li>
                        <li>studio201.io - strona software-house</li>
                        <li>architect - CMS dla potrzeb aplikacji</li>
                        <li>
                          cs-preview.studio201.io - panel administracyjny / user
                          panel
                        </li>
                      </ul>
                    </p>
                  </TabPanel>
                  <TabPanel header="Leanpassion">
                    <h3>Front-end Developer</h3>
                    <p className="m-0">
                      06-2022 - 08-2022 (outsourcing działu IT - Refericon){" "}
                      <br />
                      <span className="skillset">
                        Stack: Vue 2/3 · Nuxt3 · Tailwind · SCSS · PrimeVue ·
                        PrimeFlex · BEM · Vuetify{" "}
                      </span>
                      <br />
                      Zakres obowiązków - Tworzenie oraz utrzymywanie
                      następujących aplikacji:
                      <ul>
                        <li>CMS na potrzeby własne</li>
                      </ul>
                    </p>
                  </TabPanel>
                  <TabPanel header="Sevroll-system">
                    <h3>Młodszy programista</h3>
                    <p className="m-0">
                      06-2019 - 05-2022 <br />
                      <span className="skillset">
                        Stack: HTML · CSS · JavaScript · Wordpress
                      </span>
                      <br />
                      Zakres obowiązków - Tworzenie oraz utrzymywanie
                      następujących aplikacji:
                      <ul>
                        <li>koordynowanie projektów</li>
                        <li>utrzymywanie stron WWW</li>
                        <li>bezpośrednia komunikacja z użytkownikami</li>
                      </ul>
                    </p>
                  </TabPanel>
                  <TabPanel header="Auchan Polska">
                    <h3>Informatyk</h3>
                    <p className="m-0">
                      09-2022 - obecnie <br />
                      <span className="skillset">
                        Stack: Vue 2/3 · Nuxt3 · Tailwind · SCSS · PrimeVue ·
                        PrimeFlex · BEM · Vuetify{" "}
                      </span>
                      <br />
                      Zakres obowiązków - Tworzenie oraz utrzymywanie
                      następujących aplikacji:
                      <ul>
                        <li>wsparcie techniczne użytkowników</li>
                        <li>bezpośrednia komunikacja z użytkownikami</li>
                      </ul>
                    </p>
                  </TabPanel>
                </TabView>
              </TabPanel>
              <TabPanel header="Umiejętności.">
                <p className="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                  dolores et quas molestias excepturi sint occaecati cupiditate
                  non provident, similique sunt in culpa qui officia deserunt
                  mollitia animi, id est laborum et dolorum fuga. Et harum
                  quidem rerum facilis est et expedita distinctio. Nam libero
                  tempore, cum soluta nobis est eligendi optio cumque nihil
                  impedit quo minus.
                </p>
              </TabPanel>
            </TabView>
            <div className="flex center">
              <button className="about-me-btn">
                Zapoznaj się z projektami
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120">
          <path
            fill="#F9FAFB"
            fill-opacity="1"
            d="M0,96L1440,32L1440,0L0,0Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default AboutMe;
