import React from 'react';
import "../assets/styles/Hero.css";
import videoBg from "../assets/images/main/videoBg.mp4";
import chevronDownWhite from "../assets/icons/chevronDownWhite.svg";
function Hero() {
  return (
    <div className="hero">
      {/* <video src={videoBg} autoPlay loop muted /> */}
      <div
        className="hero-bg-video"
        dangerouslySetInnerHTML={{
          __html: `<video autoplay loop muted playsinline>
      <source src=${videoBg} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
        }}
      />
      <div className="slogan">
        <div className="slogan-content flex flex-col center">
          <h1 className="center">PIOTR SŁAWEK</h1>
          <p>PORTFOLIO</p>
        </div>
        <div className="flex center flex-col ">
          <button className="btn-hero zoom-words">Zobacz więcej</button>
          {/* <button className="btn-white-bg-empty mt-5">Zobacz więcej</button> */}
          <img className="arrow-animation" src={chevronDownWhite} alt="logo" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
