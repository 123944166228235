import React from 'react';
import Hero from "../components/Hero";
import AboutMe from "../components/AboutMe";
import InfoSection from "../components/InfoSection";
import LoginSection from "../components/LoginSection";

function HomePage() {
  return (
    <div>
      <Hero />
      <AboutMe />
      <InfoSection />
      <LoginSection className="mb-100" />
    </div>
  );
}

export default HomePage;