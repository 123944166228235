import React from 'react';
import {NavLink} from 'react-router-dom';
import "../../assets/styles/Navbar.css";
import classes from "../../assets/styles/Navbar.css";
import logoNav from "../../assets/images/main/logo.svg";
import Hamburger from "hamburger-react";
import { useState } from "react";
const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <nav className={"navbar " + (isOpen ? "navbar-click-hamburger" : "")}>
      <NavLink to="/">
        <img src={logoNav} alt="logo" />
      </NavLink>
      <ul className={"list " + (isOpen ? "menu-list list-open" : "list-none")}>
        <li>
        <NavLink to="/" end>Home</NavLink>
        </li>
        <li>
        <NavLink to="/#aboutMe" className={({isActive}) => isActive ? classes.active : undefined}>O mnie</NavLink>
        </li>
        <li>
        <NavLink to="/projects">Projekty</NavLink>
        </li>
        <li>
        <NavLink to="/#contact" className={({isActive}) => isActive ? classes.active : undefined}>Kontakt</NavLink>
        </li>
      </ul>
      <div className={isOpen ? "hamburger" : "hamburger-close"}>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
    </nav>
  );
};

export default Navbar;
