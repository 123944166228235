import React from 'react';
import "../assets/styles/InfoSection.css";
import hourglassSplit from "../assets/icons/hourglass-split.svg";
import codeSlash from "../assets/icons/code-slash.svg";
import filetypeJs from "../assets/icons/filetype-js.svg";
import personHearts from "../assets/icons/person-hearts.svg";
import CountComponent from "./CountComponent";
const InfoSection = () => {
  return (
    <div className="info-section">
      <div className="box-container">
        <div className="box">
          <div>
            <div className="flex center">
              <img
                className="svg-size"
                src={hourglassSplit}
                alt="hourglass split"
              />
            </div>
            <div className="flex center count-component">
              <CountComponent n={640} /> +
            </div>
            <p className="box-description">Godzin nauki</p>
          </div>
        </div>
        <div className="box">
          <div>
            <div className="flex center">
              <img className="svg-size" src={codeSlash} alt="hourglass split" />
            </div>
            <div className="flex center count-component">
              <CountComponent n={18145} />+
            </div>
            <p className="box-description">Linii kodu</p>
          </div>
        </div>
        <div className="box">
          <div>
            <div className="flex center">
              <img
                className="svg-size"
                src={filetypeJs}
                alt="hourglass split"
              />
            </div>
            <div className="flex center count-component">
              <CountComponent n={30} />+
            </div>

            <p className="box-description">Umiejętności</p>
          </div>
        </div>
        <div className="box">
          <div className="">
            <div className="flex center">
              <img
                className="svg-size"
                src={personHearts}
                alt="hourglass split"
              />
            </div>
            <div className="flex center count-component">
              <CountComponent n={20} />+
            </div>
            <p className="box-description">Zadowolonych współpracowników</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
