import React from 'react';
import {Link} from 'react-router-dom';
import "../../assets/styles/ErrorComponent.css";
import ErrorComponentBg from "../../assets/images/error/error-page.mp4";
function ErrorComponent() {
  return (
    <div className="error-section">
      {/* <video src={ErrorComponentBg} autoPlay loop muted /> */}
      <div
        className="error-bg-video"
        dangerouslySetInnerHTML={{
          __html: `<video autoplay loop muted playsinline>
      <source src=${ErrorComponentBg} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
        }}
      />
      <div className="error-section-container">
        <div className="error-section-content flex flex-col center text-align-center">
          <h2 className="center">404 error</h2>
          <p>Ops :-) coś poszło nie tak</p>
          <Link to="/">
            <button className="login-section-btn">
              Wróć na stronę główną
            </button>
          </Link>
        </div>
     
      </div>
    </div>
  );
}

export default ErrorComponent;
