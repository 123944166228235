import React from 'react';
import "../assets/styles/ProjectsComponent.css";
import ProjectsSectionBg from "../assets/images/projects/projects-bg.mp4";
function ProjectsComponent() {
  return (
    <div className="projects-section">
      {/* <video src={ProjectsSectionBg} autoPlay loop muted /> */}
      <div
        className="projects-bg-video"
        dangerouslySetInnerHTML={{
          __html: `<video autoplay loop muted playsinline>
      <source src=${ProjectsSectionBg} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
        }}
      />
      <div className="projects-section-container">
        <div className="projects-section-content flex flex-col center text-align-center">
          <h2 className="center">Projekty</h2>
          <p>Sekcja w trakcie budowy</p>
        </div>
     
      </div>
    </div>
  );
}

export default ProjectsComponent;
